<div class="faq">
    <div class="faq-component wrapper">
        <div class="faq-component__header">
            <h2>Frequently asked questions</h2>
        </div>
        <div class="faq-component__list">
            <ng-container *ngFor="let item of faqElements; index as i">
                <div class="faq-component__list--item" (click)="toggleItem(i)">
                    <div class="faq-component__list--item__group">
                        <div class="faq-component__list--item__group--title closed" [class.closed]="!item.isOpen">{{ item.title }}</div>
                        <app-svg-icon class="closed" [icon]="!item.isOpen ? 'cross-1' : 'cross-2'" size="medium"></app-svg-icon>
                    </div>
                    <div class="faq-component__list--item__description" [class.closed]="!item.isOpen">{{ item.description }}</div>
                </div>
            </ng-container>
        </div>
        <div class="faq__footer">
            <button class="faq__btn" (click)="goToHelp()">Go to Help centre</button>
            <div class="faq__footer--terms">
                <a [routerLink]="'/terms-and-conditions'">View Secret Fare Terms & Conditions</a>
            </div>
        </div>
    </div>
</div>
