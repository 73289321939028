import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionsSubject, Store, on } from "@ngrx/store";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState, selectBookingState } from '@core/store/store.state';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { fadeIn, fadeOut } from '@shared/animations';
import { getStationsList, searchAvailableTrips, searchAvailableTripsFailure, searchAvailableTripsSuccess, searchAvailableTripsWithReturn, searchAvailableTripsWithReturnFailure, searchAvailableTripsWithReturnSuccess, setActiveTripAssistance, setActiveTripData, setActiveTripType } from '@core/store/store.actions';
import { selectActiveTripsData, selectActiveType, selectStations } from '@core/store/store.selectors';

import { ApiService } from '@core/services/api.service';
import { AssistanceDialogComponent } from '../assistance-dialog/assistance-dialog.component';
import { CalendarHeaderComponent } from "./calendar-header/calendar-header.component";
import { ConfigService } from '@core/services/config.service';
import { DatePipe } from '@angular/common';
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '@core/services/message.service';
import { TripData } from '@core/types/data';
import { TripTypeEnum } from '@shared/enums/calendarEnum';
import moment from 'moment';
import { ofType } from '@ngrx/effects';

declare const fbq: Function | undefined;

@Component({
  selector: 'app-search-trip-form',
  templateUrl: './search-trip-form.component.html',
  styleUrls: ['./search-trip-form.component.scss'],
  animations: [ fadeIn, fadeOut ],
})
export class SearchTripFormComponent implements OnInit, OnDestroy, AfterViewInit {
  calendarHeader = CalendarHeaderComponent;

  @Input() isUpdate: boolean = false;
  @Input() isGradientBackground: boolean = false;
  homeForm: FormGroup = new FormGroup({});
  subscription: Subscription = new Subscription();
  allStations: any;
  eligibleOrigins: any;
  eligibleDestinations: any;
  isMobile: boolean = window.innerWidth < 1024 ?  true : false;
  isTablet: boolean = window.innerWidth < 1024 && window.innerWidth >= 768 ? true : false;
  showForm: boolean = true;
  isSubmittedForm: boolean = false;
  isLoading: boolean = false;
  isPassengersOpen: boolean = false;
  infoboxData: any;

  @ViewChild('origin') origin!: ElementRef<HTMLInputElement>;
  @ViewChild('destination') destination!: ElementRef<HTMLInputElement>;
  @ViewChild('destinationAuto') destinationAuto!: MatAutocomplete;
  @ViewChild('originAuto') originAuto!: MatAutocomplete;

  originInput = new FormControl('');
  destinationInput = new FormControl('');
  filteredEligibleOrigins!: any[];
  filteredEligibleDestinations!: any[];
  selectedOrigin: any;
  selectedDestination: any;

  protected _onDestroy = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
    private cd: ChangeDetectorRef,
    private actionsSubject: ActionsSubject,
    private apiService: ApiService,
    private messageService: MessageService,
    private configService: ConfigService,
    private datePipe: DatePipe,
    private ngZone: NgZone,
    private dialog: MatDialog,
  ) { }

  get depart() { return this.homeForm.controls.depart as FormControl }
  get leaving() { return this.homeForm.controls.leaving as FormControl }
  get leavingFilter() { return this.homeForm.controls.leavingFilter as FormControl }
  get going() { return this.homeForm.controls.going as FormControl }
  get goingFilter() { return this.homeForm.controls.goingFilter as FormControl }
  get return() { return this.homeForm.controls.return as FormControl }
  get isReturn() { return this.homeForm.controls.isReturn as FormControl }
  get passengers() { return this.homeForm.controls.passengers as FormControl }
  get isAssistance() { return this.homeForm.controls.isAssistance as FormControl }

  get minDate() { return this.configService.minBookableDate }
  get maxDate() { return this.configService.maxBookableDate }

  get leavingStation() {
    return this.leaving.value;
  }

  get goingStation() {
    return this.going.value;
  }

  ngOnInit() {
    this.initForm();
    this.initScreenWatcher()
    this.initPassengersHandler();

    if (this.isUpdate && this.isMobile) {
      this.showForm = false;
    }

    this.store$.dispatch(getStationsList());

    this.actionsSubject
      .pipe(
        ofType(
          searchAvailableTripsSuccess,
          searchAvailableTripsWithReturnSuccess,
        ),
      )
      .subscribe(({ tripData }) => {
        // Only set tripData on a successful search response...
        // Fixes ENT-2683 where it was possible to make it appear a ticket purchased for a different O/D combination
        this.store$.dispatch(setActiveTripData({ tripData }));
        this.isLoading = false;
      });

    this.actionsSubject
      .pipe(
        ofType(
          searchAvailableTripsFailure,
          searchAvailableTripsWithReturnFailure
        ),
      )
      .subscribe(() => {
        this.isLoading = false;
      });

    // reload the specific-available stations
    if (this.leaving.value) {
      this.changeOrigin({ option: { value: this.leaving.value } });
    }

    if (this.going.value) {
      this.changeDestination({ option: { value: this.going.value } });
    }
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.store$.select(selectStations).subscribe(res => {
        this.allStations = res;
        this.eligibleOrigins = res;
        this.eligibleDestinations = res;
        this.filteredEligibleOrigins = this.eligibleOrigins?.slice();
        this.filteredEligibleDestinations = this.eligibleDestinations?.slice();
        this.refresh();
      })
    );

    this.subscription.add(
      this.store$.select(selectActiveType).subscribe(res => {
        if (!res) {
          this.store$.dispatch(setActiveTripType({data: TripTypeEnum.OneWay}));
        }
        this.refresh();
      })
    );

    this.subscription.add(
      this.isReturn.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(res => {
        if (res === TripTypeEnum.Return) {
          this.store$.dispatch(setActiveTripType({data: TripTypeEnum.Return}))
          this.refresh();
        } else {
          this.store$.dispatch(setActiveTripType({data: TripTypeEnum.OneWay}))
          this.return.setValue('');
          this.refresh();
        }
      })
    );

    this.subscription.add(
      this.isAssistance.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(res => {
        if (!res) {
          this.store$.dispatch(setActiveTripAssistance({isAssistance: false}));
        } else {
          this.openAssistanceDialog();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.originInput.setValidators(Validators.required);
    this.destinationInput.setValidators(Validators.required);

    this.homeForm = this.formBuilder.group({
      leaving: this.originInput,
      leavingFilter: [''],
      going: this.destinationInput,
      goingFilter: [''],
      depart: ['', Validators.required],
      return: [''],
      passengers: [1, Validators.required],
      isReturn: [TripTypeEnum.OneWay, Validators.required],
      isAssistance: [false, Validators.required]
    });

    if (this.isUpdate) {
      this.subscription.add(
        this.store$.select(selectActiveTripsData)
          .pipe(distinctUntilChanged())
          .subscribe(res => {
            if (res) {
              if (res.origin) {
                this.leaving.setValue(res.origin);
              }
              if (res.destination) {
                this.going.setValue(res.destination);
              }
              if (res.date) {
                this.depart.setValue(new Date(res.date));
              }
              if (res.numberOfTravellers) {
                this.passengers.setValue(res.numberOfTravellers);
              }
              if (res.type) {
                if (res.type === TripTypeEnum.Return) {
                  this.isReturn.setValue(TripTypeEnum.Return);
                } else if (res.type === TripTypeEnum.OneWay) {
                  this.isReturn.setValue(TripTypeEnum.OneWay);
                }
              }
              if (res.returnDate) {
                this.return.setValue(new Date(res.returnDate));
              }
              if (res.isAssistance) {
                this.isAssistance.setValue(true);
              } else {
                this.isAssistance.setValue(false);
              }
            }
            this.refresh();
          })
      );
    }
  }

  filterOrigins(): void {
    const filterValue = this.origin.nativeElement.value.toLowerCase();
    this.filteredEligibleOrigins = (this.eligibleOrigins as any[]).filter(o => o.name.toLowerCase().includes(filterValue));
  }

  filterDestinations(): void {
    const filterValue = this.destination.nativeElement.value.toLowerCase();
    this.filteredEligibleDestinations = (this.eligibleDestinations as any[]).filter(o => o.name.toLowerCase().includes(filterValue));
  }

  userManuallyTypedOrigin(): void {
    //User hasn't selected an option or user has full deleted the text from a previously selected option
    if (!this.selectedOrigin || this.origin.nativeElement.value == "") {
      this.resetOrigin(this.originInput);
      return;
    }

    //User has partially changed a previously selected option - set the text back to their selection
    if (this.selectedOrigin) {
      this.originInput.setValue(this.selectedOrigin);
      return;
    }
  }

  userManuallyTypedDestination(): void {
    //User hasn't selected an option or user has full deleted the text from a previously selected option
    if (!this.selectedDestination || this.destination.nativeElement.value == "") {
      this.resetDestination(this.destinationInput);
      return;
    }

    //User has partially changed a previously selected option - set the text back to their selection
    if (this.selectedDestination) {
      this.destination.nativeElement.value = this.selectedDestination.name;
      return;
    }
  }

  mapToStationName(station: any) {
    return station && station.name ? station.name : "";
  }

  initScreenWatcher() {
    window.onresize = (e) =>
    {
        this.ngZone.run(() => {
          window.innerWidth < 1024
            ? this.isMobile = true
            : this.isMobile = false;

            window.innerWidth < 1024 && window.innerWidth >= 768
            ? this.isTablet = true
            : this.isTablet = false;
        });
    };
  }

  openAssistanceDialog() {
    const dialogRef = this.dialog.open(AssistanceDialogComponent, {
      disableClose: true,
      closeOnNavigation: true,
    });

    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((isAssistance: boolean) => {
        isAssistance
          ? this.store$.dispatch(setActiveTripAssistance({isAssistance: isAssistance}))
          : this.isAssistance.setValue(false);
      });
  }

  initPassengersHandler() {
    document.body.addEventListener('click', (element: any) => {
      if (element?.target?.className) {
        const classString: string | SVGAnimatedString =  element?.target?.className;
        if (typeof classString === 'string') {
          const clickOnDropdown = classString.includes('search-trip__form__header__passenger');
          if (!clickOnDropdown) {
            this.isPassengersOpen = false;
          }
        } else if (classString?.baseVal) {
          const clickOnDropdown = classString.baseVal.includes('search-trip__form__header__passenger');
          if (!clickOnDropdown) {
            this.isPassengersOpen = false;
          }
        }
      }
    });
  }

  increase() {
    const value = this.passengers.value;
    if (value < 9) {
      this.passengers.setValue(value + 1);
    }
  }

  decrease() {
    const value = this.passengers.value;
    if (value > 1) {
      this.passengers.setValue(value - 1);
    }
  }

  submit() {
    this.isSubmittedForm = true;
    this.isLoading = true;

    // Handle return dates being before depart dates
    if (this.isReturn.value === 'return' && this.return.value) {
      const momentObject = moment(this.return.value, 'MM-DD-YYYY')
      const returnDateIsBeforeDepart = momentObject.isBefore(this.depart.value)
      if (returnDateIsBeforeDepart) {
        this.messageService.showInfoToast('Departure date selected after return date. Please select your return date again.')
        this.isLoading = false;
        return;
      }
    }

    if (this.homeForm.invalid) {
      this.isLoading = false;
      return;
    }
    let date;
    if (this.depart.value instanceof Date) {
      date = this.datePipe.transform(this.depart.value,"YYYY-MM-dd");
    } else if (typeof this.depart.value === 'object') {
      date = this.depart.value.format('YYYY-MM-DD');
    } else {
      date = this.depart.value;
    }

    const origin = this.leaving.value.code;
    const destination = this.going.value.code;
    const numberOfTravellers = this.passengers.value;

    let returnDate;

    if (this.isReturn.value === 'return') {
      if (this.return.value === "") {
        this.isLoading = false;
        return;
      }

      if (this.return.value instanceof Date) {
        returnDate = this.datePipe.transform(this.return.value,"YYYY-MM-dd");
      } else if (typeof this.return.value === 'object') {
        returnDate = this.return.value.format('YYYY-MM-DD');
      } else {
        returnDate = this.return.value;
      }
    }

    const tripData = {
      origin: this.leaving.value,
      destination: this.going.value,
      numberOfTravellers: this.passengers.value,
      type: this.isReturn.value,
      date,
      returnDate: returnDate !== "" ? returnDate : undefined,
    };

    // track search submit
    (window as any).dataLayer.push({ 'event': 'SecretFareSearch' });
    typeof fbq !== 'undefined' && fbq('trackCustom', 'SecretFareSearch');

    if (this.isReturn.value === 'return') {
      this.store$.dispatch(searchAvailableTripsWithReturn({
        date,
        origin,
        destination,
        numberOfTravellers,
        inboundDate: returnDate,
        tripData,
      }));
    } else {
      this.store$.dispatch(searchAvailableTrips({
        date,
        origin,
        destination,
        numberOfTravellers,
        tripData,
      }));
    }
  }

  changeDestination(event: any) {
    this.apiService.fetchOriginStations(event.option.value.code).subscribe((res: any) => {
      this.eligibleOrigins = res.stations;
    })
    this.selectedDestination = event.option.value;
  }

  changeOrigin(event: any) {
    this.apiService.fetchDestinationStations(event.option.value.code).subscribe((res: any) => {
      this.eligibleDestinations = res.stations;
    })
    this.selectedOrigin = event.option.value;
  }

  checkReturnState() {
    if (this.isReturn.value === TripTypeEnum.OneWay) {
      this.isReturn.setValue(TripTypeEnum.Return);
    }
  }

  refresh() {
    this.cd.detectChanges();
  }

  resetDestination(formControl: any, event?: any) {
    this.eligibleOrigins = this.allStations;
    this.resetFormControl(formControl, event);
    this.resetAutocompleteControl(this.destinationAuto);
    this.selectedDestination = null;
  }

  resetOrigin(formControl: any, event?: any) {
    this.eligibleDestinations = this.allStations;
    this.resetFormControl(formControl, event);
    this.resetAutocompleteControl(this.originAuto);
    this.selectedOrigin = null;
  }

  private resetFormControl(formControl: any, event?: any) {
    formControl.setValue(undefined);

    if (event) {
      event.stopPropagation();
    }
  }

  private resetAutocompleteControl(control: MatAutocomplete) {
    control.options.forEach(item => item.deselect())
  }
}
