import { Money, Price } from "@core/types/core";

type ReturnType<T> = { outbound: T, inbound: T };

export type CheckoutRequest = {
    searchId: string;
    customer: Customer;
    buy: BuyRequest;
};

type SingleProduct = {
    date: string;
    timeband: TimebandBuy;
};

type ReturnProduct = ReturnType<SingleProduct>;

type BuyRequest = SingleProduct | ReturnProduct;

type Customer = {
    email: string;
    firstName: string;
    lastName: string;
}

type TimebandBuy = {
    id: string;
    isAvailable: boolean;
    price: Price;
};

export type CheckoutResponse = {
    session: string;
    checkoutId: string;
    transactionReference: string;
    selectedProduct: BuyRequest;
    paymentConfig: PaymentConfig;
    paymentSession: PaymentSession;
}

type PaymentConfig = {
    applicationName: string;
    environment: string;
    clientKey: string;
    countryCode: string;
    locale: string;
};

type PaymentSession = {
    id: string;
    expiresAt: string;
    sessionData: string;
    processId: string;
    reference: string;
    returnUrl: string;
    userId: string;
    amount: Money;
};

export function isSingleProduct(product: any): product is SingleProduct {
    const singleProduct = product as SingleProduct;
    return (singleProduct !== undefined && singleProduct !== null)
        && singleProduct.date !== undefined && singleProduct.timeband?.isAvailable;
}

export function isReturnProduct(product: any): product is ReturnProduct {
    if (isReturnType<SingleProduct>(product)) {
        return isSingleProduct(product.outbound) && isSingleProduct(product.inbound);
    }
    return false;
}

export function isReturnType<T>(obj: any): obj is ReturnType<T> {
    const returnType = obj as ReturnType<T>;
    return returnType !== undefined &&
        returnType !== null &&
        (returnType.outbound !== undefined || returnType.inbound !== undefined);
}
