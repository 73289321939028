import { Action, createReducer, on } from '@ngrx/store';
import {
  clearActiveReturnTripPrice,
  clearActiveReturnTripTimeband,
  clearActiveTrip,
  clearActiveTripAssistance,
  clearActiveTripEmail,
  clearActiveTripPrice,
  clearActiveTripTimeband,
  clearActiveTripType,
  clearStationsList,
  getStationsListSuccess,
  resetAllTripData,
  searchAvailableTripsSuccess,
  searchAvailableTripsWithReturnSuccess,
  setActiveReturnTripPrice,
  setActiveReturnTripTimeband,
  setActiveTripAssistance,
  setActiveTripData,
  setActiveTripEmail,
  setActiveTripFirstName,
  setActiveTripLastName,
  setActiveTripPrice,
  setActiveTripTemplate,
  setActiveTripTimeband,
  setActiveTripType,
  setCheckoutCompleted,
  setStationsList,
  updateReturnTripDate,
  updateTripDate
} from './store.actions';

import { AppState } from './store.state';
import { TripTypeEnum } from '@shared/enums/calendarEnum';

export const initialState: AppState = {
  stations: [],
  activeTripCalendarTemplate: null,
  activeTripData: null,
  checkoutCompleted: null,
};

const reducer = createReducer(
  initialState,
  on(getStationsListSuccess, (state, { data }) => ({
    ...state,
    stations: data
  })),
  on(searchAvailableTripsSuccess, (state, { data }) => ({
    ...state,
    activeTripCalendarTemplate: data
  })),
  on(searchAvailableTripsWithReturnSuccess, (state, { data }) => ({
    ...state,
    activeTripCalendarTemplate: data
  })),
  on(setStationsList, (state, { stations }) => ({
    ...state,
    stations: stations
  })),
  on(setActiveTripTemplate, (state, { activeTrip }) => ({
    ...state,
    activeTripCalendarTemplate: activeTrip
  })),
  on(setActiveTripData, (state, { tripData }) => ({
    ...state,
    activeTripData: tripData
  })),
  on(clearStationsList, (state) => ({
    ...state,
    stations: []
  })),
  on(clearActiveTrip, (state) => ({
    ...state,
    activeTripCalendarTemplate: null,
    activeTripData: null,
  })),
  on(updateTripDate, (state, {date}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      date: date
    }
  })),
  on(updateReturnTripDate, (state, {date}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      returnDate: date
    }
  })),
  on(setActiveTripTimeband, (state, {timeband, durations}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      timeband: timeband,
      durations: durations,
    }
  })),
  on(clearActiveTripTimeband, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      timeband: null,
      durations: null,
    }
  })),
  on(setActiveReturnTripTimeband, (state, {timeband, durations}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      returnTimeband: timeband,
      returnDurations: durations,
    }
  })),
  on(clearActiveReturnTripTimeband, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      returnTimeband: null,
      returnDurations: null,
    }
  })),
  on(setActiveTripPrice, (state, {price}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      price: price,
    }
  })),
  on(clearActiveTripPrice, (state, {}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      price: null,
    }
  })),
  on(setActiveReturnTripPrice, (state, {price}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      returnPrice: price,
    }
  })),
  on(clearActiveReturnTripPrice, (state, {}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      returnPrice: null,
    }
  })),
  on(setActiveTripEmail, (state, { email }) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      email: email
    }
  })),
  on(clearActiveTripEmail, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      email: null
    }
  })),
  on(setActiveTripFirstName, (state, { firstName }) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      firstName: firstName
    }
  })),
  on(setActiveTripLastName, (state, { lastName }) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      lastName: lastName
    }
  })),
  on(setActiveTripType, (state, { data }: { data: TripTypeEnum }) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      type: data
    }
  })),
  on(clearActiveTripType, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      type: null
    }
  })),
  on(setActiveTripAssistance, (state, { isAssistance }) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      isAssistance: isAssistance
    }
  })),
  on(clearActiveTripAssistance, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      isAssistance: null
    }
  })),
  on(setCheckoutCompleted, (state, { completed }) => ({
    ...state,
    checkoutCompleted: completed,
  })),
  on(resetAllTripData, (state) => ({
    ...state,
    activeTripCalendarTemplate: null,
    activeTripData: null,
    checkoutCompleted: null,
  })),
);

export const appReducer = (state: AppState = initialState, action: Action) => reducer(state, action);
